import { createApp } from 'vue'
import App from './App.vue'
import 'vant/lib/index.css';
import vant from 'vant';
import store from './store/store.js';
import router from './router/router.js';
import './permission'
import './assets/iconFont/iconfont.css'


const app = createApp(App)
app.use(vant);
app.use(store);
app.use(router)
app.mount('#app')
