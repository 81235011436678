<template>
  <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
  >
    <!--在 Vue 3 中，当在模板中使用 v-for 遍历 transcript 数组并将 item 作为 van-cell 的 title 属性值时，如果显示为 Object object，是因为直接将一个对象作为字符串输出时，JavaScript 会默认调用对象的 toString() 方法，而这个方法通常返回 [object Object]。
    使用 item.id 作为 v-for 的 key 属性值以确保每个循环项有唯一的标识。同时，将 item.text 数组通过 join(' ') 方法转换为字符串后作为 van-cell 的 title 属性值，这样就可以显示 text 属性的内容了。-->
    <van-cell v-for="item in transcript" :key="item.id" center>
      <template #icon>
        <van-icon name="play-circle" size="20px" @click="handleItemClick(item)" color="#438EDB"/>
      </template>
      <template #title>
        <span v-for="(word, index) in item.text" :key="word" @click="changeWordColor(word)" :class="{ highlighted: highlightedWord === word }">
            {{ index > 0? ' ' : '' }}{{ word }}
          </span>
      </template>
    </van-cell>
  </van-list>
</template>

<script>
import { showToast } from 'vant';
import { mapMutations } from 'vuex';
import { ref } from 'vue';
import { showDialog } from 'vant';

function timeStringToSeconds(timeString) {
  const parts = timeString.split(':');//parseInt函数在解析字符串时，会从字符串的开头开始解析，直到遇到一个不能转换为数字的字符为止
  let seconds = 0;
  for (let i = parts.length - 1; i >= 0; i--) {
    seconds += parseInt(parts[i]) * Math.pow(60, parts.length - 1 - i);
  }
  const outerParts = timeString.split(',');
  const milliseconds = parseInt(outerParts[1]) / 1000
  seconds += milliseconds
  return seconds;
}

function playAudio(url) {
  const audio = new Audio(url);
  audio.play();
}

export default {
  props: {
    options: {
      type: String, // 根据实际值的类型定义
      default: '',
    }
  },
  data() {
    return {
      contentLines: [],
      transcript: [],
      highlightedWord: null,
    };
  },
  setup(props) {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          list.value.push(list.value.length + 1);
        }

        // 加载状态结束
        loading.value = false;

        // 数据全部加载完成
        if (list.value.length >= 40) {
          finished.value = true;
        }
      }, 1000);
    };
    console.log("SrtList.setup(props)", props.options);
    //const onChange = (index) => showToast('当前 Swipe 索引：' + index);
    return {
      list,
      onLoad,
      loading,
      finished,
    };
  },
  methods: {
    changeWordColor(word) {
      if (this.highlightedWord === word) {
        this.highlightedWord = null;
      } else {
        this.highlightedWord = word;
        // 调用接口获取翻译并显示在弹框中
        fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)
            .then(response => response.json())
            .then(data => {
              if (data && data.length > 0 && data[0].meanings && data[0].meanings.length > 0 && data[0].meanings[0].definitions && data[0].meanings[0].definitions.length > 0 && data[0].phonetics && data[0].phonetics.length > 0) {
                const phonetic = data[0].phonetic? data[0].phonetic : '';
                const partOfSpeech = data[0].meanings[0].partOfSpeech? data[0].meanings[0].partOfSpeech : '';
                const definition = data[0].meanings[0].definitions[0].definition? data[0].meanings[0].definitions[0].definition : '';
                const audioUrl = data[0].phonetics[0].audio? data[0].phonetics[0].audio : '';
                //const message = `${word} /${phonetic}/ <button onclick="playAudio('${audioUrl}')">按钮</button>\n${partOfSpeech}\n${definition}`;
                const message = `${word} ${phonetic} \n${partOfSpeech}\n${definition}`;
                showDialog({
                  message: message,
                  messageAlign: "left"
                }).then(() => {
                  // on close
                });
              } else {
                showDialog({
                  message: `未找到 ${word} 的翻译`,
                  messageAlign: "left"
                }).then(() => {
                  // on close
                });
              }
            })
            .catch(error => {
              showDialog({
                message: `获取 ${word} 翻译时出错：${error}`,
                messageAlign: "left"
              }).then(() => {
                // on close
              });
            });
      }
    },
    getSplitText(sentence) {
      const words = sentence.split(' ');
      return words
    },
    ...mapMutations(['setCurrentItemId', "setCurrentItemStartTime", "setCurrentItemEndTime"]),
    handleItemClick(item) {
      this.setCurrentItemStartTime(item.startTime)
      this.setCurrentItemEndTime(item.endTime)
    },
    getWindowHeight(){},
    async getTxtContentUsingFetch(options) {
      try {
        const response = await fetch(options.src);
        if (response.ok) {
          const content = await response.text();
          this.contentLines = content.split('\n');
          for (let i = 0; i < this.contentLines.length; i += 4) {
            if (this.contentLines[i] && this.contentLines[i + 1] && this.contentLines[i + 2]) {
              this.transcript.push({
                id: parseInt(this.contentLines[i]),
                timeRange: this.contentLines[i + 1],
                text: this.getSplitText(this.contentLines[i + 2]),
                //randomIndexes: this.getRandomIndexes(this.contentLines[i + 2]),
                startTime: timeStringToSeconds(this.contentLines[i + 1].split(' --> ')[0]),
                endTime: timeStringToSeconds(this.contentLines[i + 1].split(' --> ')[1]),
              });
            }
          }
          //设置初始值
          if (this.transcript.length > 0) {
            this.setCurrentItemId(parseInt(this.contentLines[0]));
            this.setCurrentItemStartTime(timeStringToSeconds(this.contentLines[1].split(' --> ')[0]));
            this.setCurrentItemEndTime(timeStringToSeconds(this.contentLines[1].split(' --> ')[1]));
            //console.log("SrtList:this.contentLines[1].split(' --> ')[0])", timeStringToSeconds(this.contentLines[1].split(' --> ')[0]))
          }
        } else {
          console.error('无法获取 txt 文件内容');
        }
      } catch (error) {
        console.error('发生错误：', error);
      }
    }
  },
  mounted() {
    console.log("SrtList:mounted()执行了")
    this.getWindowHeight();
    this.getTxtContentUsingFetch(this.options);
  },
  name: "SrtList"
}
</script>

<style scoped>
.highlighted {
  color: #2c3e50;
}

</style>