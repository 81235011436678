<template>
  <div class="video-container">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    startTime: {
      type: Number,
      default: 0
    },
    endTime: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    this.initVideoPlayer();
    this.setupVideoEndLogic();
    /*this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log('onPlayerReady', this);
    });*/
  },
  methods: {
    setupVideoEndLogic() {
      //console.log("VideoPlayer.setupVideoEndLogic()执行")
      //不加这段代码，如果某一段播完了暂停了，再按播放按钮就没办法从这段的开头播放了
      this.player.on('play', () => {
        this.player.currentTime(this.startTime);
        //console.log("VideoPlayer.setupVideoEndLogic().this.startTime;", this.startTime)
        //this.player.play();
      });
      this.player.on('timeupdate', () => {
        if (this.player.currentTime() >= this.endTime) {
          this.player.pause();
          //console.log("VideoPlayer.setupVideoEndLogic().this.endTime;", this.endTime)
        }
      });
    },
    initVideoPlayer() {
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        this.player.log('onPlayerReady', this);
        this.player.addClass('vjs-16-9');
        this.player.controlBar.progressControl.disable();
      });
      /*window.addEventListener('resize', () => {
        //this.player && this.player.resize();
        const container = document.querySelector('.video-container');
        const videoPlayer = this.$refs.videoPlayer;
        videoPlayer.style.width = container.clientWidth + 'px';
        videoPlayer.style.height = container.clientHeight + 'px';
        window.alert(container.clientWidth + 'px')
      });
      window.addEventListener('resize', () => {
        const container = document.querySelector('.video-container');
        //const videoPlayer = this.$refs.videoPlayer;
        //videoPlayer.style.width = container.clientWidth + 'px';
        //videoPlayer.style.height = container.clientHeight + 'px';
        window.alert(container.clientWidth + 'px')
      });*/
      // 监听窗口大小变化，实现自适应
      /*window.addEventListener('resize', () => {
        //this.player && this.player.resize();
        const container = document.querySelector('.video-container');
        const videoPlayer = this.$refs.videoPlayer;
        videoPlayer.style.width = container.clientWidth + 'px';
        videoPlayer.style.height = container.clientHeight + 'px';
        window.alert(container.clientWidth + 'px')
      });*/
    },
    playVideo () {
      this.player.play();
    },
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    startTime(newVal) {
      if (this.player && newVal!== this.player.currentTime()) {
        //console.log("VideoPlayer.watch.startTime", newVal)
        this.player.currentTime(newVal);
        this.player.play()
      }
    },
    endTime(newVal) {
      if (this.player){
        this.player.endTime = newVal
      }
    }
  }
}
</script>

<style>

.video-container {
  width: 60%;
  height: auto;
  margin: 20px;
}

/*.video-js {
  width: 100%;
  height: 100%;
}
.video-container {
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  position: relative;
  display: block;
}*/

</style>