import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const ExpiresInKey = 'Admin-Expires-In'

export function setToken (token) {
    return Cookies.set(TokenKey, token, { expires: 7 })
}

export function removeToken () {
    return Cookies.remove(TokenKey)
}

export function getToken () {
    return Cookies.get(TokenKey)
}

export function setExpiresIn (time) {
    return Cookies.set(ExpiresInKey, time)
}