<!--条款注释组件-->
<template>
  <div class="explanatory">
    登录即表示您已阅读并同意<a class="pointerCursor" @click="$router.push('/termsService')">服务条款</a>
  </div>
</template>
<script>
export default {
  name: 'explanatory'
}
</script>
