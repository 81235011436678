import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '../components/AppLayout.vue';
import HomePage from '../components/HomePage.vue';
import TestComponent from "@/components/TestComponent.vue";
import ForgetPassword from "@/views/ForgetPassword.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import VideoList from '../components/VideoList.vue';

const routes = [
    {
        path: '/',
        component: AppLayout,
        children: [
            {
                path: '/home',
                component: HomePage
            },
            {
                path: '/',
                component: TestComponent
            },
            {
                path: '/more',
                component: VideoList
            },
            {
                path: '/:anyNumber',
                component: TestComponent,
                props: true
            }
        ]
    },
    {
        path: '/forgetPassword',
        component: ForgetPassword
    },
    {
        path: '/register',
        component: RegisterPage
    },
    {
        path: '/login',
        component: LoginPage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
