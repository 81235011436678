/*- 第一个斜杠"/"表示正则表达式的开始。
- "phone"和"iPhone"等表示需要匹配的字符串。
- "|"表示"或"的意思，用于将多个字符串连接在一起。
- 最后一个斜杠"/"表示正则表达式的结束。
- "i"表示忽略字符串的大小写。

具体来说，它匹配的设备和浏览器包括：
- phone: 电话
- pad: 平板电脑
- pod: iPod播放器
- iPhone: iPhone手机
- iPod: iPod播放器
- ios: 苹果操作系统
- iPad: iPad平板电脑
- Android: 安卓操作系统
- Mobile: 移动设备
- BlackBerry: 黑莓手机
- IEMobile: 微软IE浏览器（移动版）
- MQQBrowser: QQ浏览器
- JUC: 联想浏览器
- Fennec: 火狐浏览器
- wOSBrowser: webOS浏览器
- BrowserNG: 索尼Ericsson手机浏览器
- WebOS: palm系统
- Symbian: 塞班系统
- Windows Phone: 微软Windows手机操作系统*/
export const _isMobile = function () {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}
