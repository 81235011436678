import axios from 'axios'
import { getToken } from '@/utils/auth'
//import errorCode from '@/utils/errorCode'
import { tansParams } from '@/utils/ruoyi'
//import { showNotify } from 'vant'

import cache from '@/plugins/cache'
import qs from 'qs'
// 是否显示重新登录
// eslint-disable-next-line no-unused-vars
let isReloginShow

axios.defaults.headers['content-type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/api',
  // 超时
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  const formData = config.headers?.formData || false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers.access_token = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  if (formData) {
    config.headers['content-type'] = 'application/x-www-form-urlencoded'
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params)
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    let dataMain
    if (formData) {
      dataMain = qs.stringify(config.data)
      config.data = dataMain
    } else {
      dataMain = typeof config.data === 'object' ? JSON.stringify(config.data) : config.data
    }
    const requestObj = {
      url: config.url,
      dataMain,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url // 请求地址
      const s_data = sessionObj.data // 请求数据
      const s_time = sessionObj.time // 请求时间
      const interval = 1000 // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交'
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})


/*// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = res.data.message || errorCode[code] || res.data.msg || errorCode.default
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code === 401) {
    if (!isReloginShow) {
      isReloginShow = true
      showNotify({ type: 'warning', message: '登录状态已过期，需要重新登录', duration: 5000 })
      store.dispatch('LogOut').then(() => {
        // 如果是登录页面不需要重新加载
        if (window.location.hash.indexOf('#/login') != 0) {
          location.href = '/index'
        }
      })
    }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code === 500) {
    if (res.data == null || res.data == undefined || res.apiInfo == null || res.apiInfo == undefined) {
      showNotify({ type: 'warning', message: msg, duration: 5000 })
    } else {
      //console.log("===========================request.js:非500:msg", msg)
      showNotify({ type: 'warning', message: msg, duration: 5000 })
    }
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    //当code为51004，msg为res.data.message，即提问包含敏感词汇,请调整
    showNotify({ type: 'warning', message: msg, duration: 5000 })
    return Promise.reject('error')
  } else {
    //code是200的时候，msg是res.data.message，即成功；一访问页面就出现
    //res.data是{"code":0,"message":"成功","data":{"pageNum":0,"pageSize":10,"size":0,"startRow":0,"endRow":0,"total":0,"pages":0,"list":[],"prePage":0,"nextPage":0,"isFirstPage":false,"isLastPage":true,"hasPreviousPage":false,"hasNextPage":false,"navigatePages":8,"navigatepageNums":[],"navigateFirstPage":0,"navigateLastPage":0,"firstPage":0,"lastPage":0},"success":true}
    return Promise.resolve(res.data)
  }
},
error => {
  console.log('err' + error)
  let { message } = error
  if (message == 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  // ElMessage({
  //   message: message,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  showNotify({ type: 'warning', message: message, duration: 5000 })
  return Promise.reject(error)
}
)*/

export default service
