import request from '@/utils/request'
export function getUserStatus(){
  return request({
    url: '/appUser/getUserStatus',
    method: 'get'
  })
}
export function getInfo () {
  return request({
    url: '/appUser/getUserInfo',
    method: 'get'
  })
}

export function login (username, password) {
  return request({
    url: '/authentication/form',
    headers: {
      isToken: false,
      formData: true
    },
    method: 'post',
    data: { username, password }
  })
}