<!--登录页面-->
<template>
  <div class="content">
    <div class="welcome">
      <div>欢迎来到ChatGPT</div>
      <div>AI世界即将为您开启</div>
    </div>
    <div class="main">
      <div class="mainContainer">
       <div class="loginName">账号登录</div>
       <div class="main_form">
         <van-form @submit="onSubmit">
           <van-cell-group inset>
             <van-field
                 v-model="form.username"
                 name="请输入手机号"
                 placeholder="请输入手机号"
                 :rules="[{ required: true, message: '请输入手机号' },
                { validator: validatorTel, message: '手机号输入不合法' }]"
             />
             <van-field
                 v-model="form.password"
                 :type="isShow ? 'text' : 'password' "
                 name="请输入密码"
                 placeholder="请输入密码"
                 :right-icon="isShow ? 'eye-o' : 'closed-eye'"
                 @click-right-icon="changePasswordIcon"
                 :rules="[{ required: true, message: '请输入密码' }]"
             />
           </van-cell-group>
           <div>
             <van-button block type="primary" native-type="submit" class="formButton" color="#16a37f">
               登录
             </van-button>
           </div>
         </van-form>
         <div class="row-between toPasswordRegister">
           <div class="pointerCursor" @click="$router.push('/forgetPassword')">
             忘记密码？
           </div>
           <div class="pointerCursor" @click="$router.push('/register')">
             新用户账号注册
           </div>
         </div>
       </div>
      </div>
      <!--<explanatory></explanatory>-->
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import explanatory from '@/components/explanatory'

/*这段代码是一个Vue组件的导出，默认导出一个对象。组件使用了Vue 3的Composition API，包含了一个setup函数，返回了一些响应式数据和函数。
其中，isShow、onSubmit、methods和state都是响应式数据。
组件还使用了store和router，可能是一个带有登录功能的页面或组件。
组件还引入了一个名为explanatory的组件。*/
export default {
  setup () {
    const isShow = ref(false)
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      form: {
        username: '',
        password: ''
      },
      validatorTel: false,
      phoneGui: /^[0-9]{11}$/
    })
    state.validatorTel = (val) => state.phoneGui.test(val)
    /*这段代码定义了一个onSubmit函数，它接受一个values参数。
    当这个函数被调用时，它会使用VueX的store.dispatch方法调用两个action：Login和GetInfo。
    这些action可能是用于处理用户登录和获取用户信息的。当这些action都成功完成时，函数会检查store.getters.level是否为0。
    如果是，则导航到'/'路径，否则导航到'/chart'路径。如果这些action之一或两个都返回一个错误，则会在catch块中处理错误。*/

    /*Login和GetInfo是使用VueX实现的action，它们通常在store目录下的一个或多个文件中定义。
    在store对象中，通常会包含一个actions属性，这个属性是一个对象，其中每个属性对应一个action的实现。
    在这些action实现中，我们可以访问store对象的属性和方法，比如state、commit、dispatch、getters等等。*/

    /*具体来说，它们可能在store目录下的actions.js或者modules子目录中的某个文件中定义。
    在这些文件中，会有一个JavaScript对象（通常称为store对象），包含了一些状态、操作和getter。
    这些操作就是我们在组件中通过store.dispatch方法调用的actions。*/
    const onSubmit = (values) => {
      try {
        store.dispatch('Login', state.form).then((res) => {
          store.dispatch('GetInfo').then((res) => {
            router.push('/home')
          }).catch((error) => {
            console.log(error)
          })
        }).catch((error) => {
          console.log(error)
        })
      }catch (e) {
        console.log(e)
      }
    }
    const methods = reactive({
      changePasswordIcon () {
        isShow.value = !isShow.value
      }
    })
    return {
      isShow,
      onSubmit,
      ...toRefs(methods),
      ...toRefs(state)
    }
  },
  components: {
    explanatory
  }
}

</script>

