<!--注册页面-->
<template>
  <div class="content">
    <div class="welcome">
      注册思维独角兽
    </div>
    <div class="main">
      <div class="main_form">
        <van-form @submit="onSubmit" ref="companyFormRef">
          <van-cell-group inset>
            <van-field
                v-model="form.phone"
                name="请输入手机号"
                placeholder="请输入手机号"
                :rules="[{ required: true, message: '请输入手机号' },
                { validator: validatorTel, message: '手机号输入不合法' }]"
            />
            <van-field
                v-model="form.password"
                name="请输入8-16位数字和字母组合的密码"
                placeholder="请输入8-16位数字和字母组合的密码"
                :type="isShow ? 'text' : 'password' "
                :right-icon="isShow ? 'eye-o' : 'closed-eye'"
                @click-right-icon="changePasswordIcon"
                :rules="[
                    { required: true, message: '请输入密码' },
                    { validator: validatorPassword, message: '请输入8-16位数字和字母组合的密码' }
                ]"
            />
            <van-field
                v-model="form.smsCode"
                type="text"
                name="请输入验证码"
                placeholder="请输入验证码"
                :rules="[{ required: true, message: '请输入验证码' }]"
            >
              <template #button>
                <van-button size="small" type="primary"  v-if="isSend" color="#16a37f">{{waitingTime}}s</van-button>
                <van-button
                    size="small"
                    type="primary"
                    v-else
                    @click="loadingGainPhoneCodeFun"
                    :disabled="isClick" color="#16a37f">发送验证码</van-button>
              </template>
            </van-field>
          </van-cell-group>
          <van-button block type="primary" native-type="submit" class="formButton" :disabled="isClick" color="#16a37f">
            注册
          </van-button>
        </van-form>
        <div class="row-center toPasswordRegister">
          <div class="pointerCursor">
            已有账号？<span class="buleKey" @click="$router.push('/login')" >去登录 ></span>
          </div>
        </div>
      </div>
      <!--<explanatory></explanatory>-->

    </div>
    <!--图片二维码-->
    <van-popup v-model:show="codeImg" :style="{ width: '80%', padding: '2%' }">
      <van-cell-group inset class="row-between codeImg">
        <van-field v-model="codeImgNumber" placeholder="请输入验证码" />
        <img :src="codeUrl" @click="getCode" class="login-code-img"/>
      </van-cell-group>
      <van-button block type="primary" @click="gainPhoneCodeFun" :disabled="codeImgNumber == ''" color="#16a37f">
        发送
      </van-button>
    </van-popup>
  </div>
</template>

<script>
import explanatory from '@/components/explanatory'
import { reactive, toRefs, watch, defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { showNotify } from 'vant'
import { register } from '@/api/register'
import { useRouter } from 'vue-router'
import store from "@/store/store";
export default defineComponent({
  name: 'RegisterPage',
  setup () {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const state = reactive({
      form: {
        smsCode: '',
        phone: '',
        password: ''
      },
      isShow: false,
      passwordGui: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
      isClick: true,
      validatorPassword: false,
      isSend: false, // 是否发送验证码
      isWaiting: false,
      timer: '',
      waitingTime: 120,
      validatorTel: false,
      phoneGui: /^[0-9]{11}$/,
      codeUrl: '',//img二维码地址
      codeImg: false,//img二维码地址弹框是否显示
      codeImgNumber: '',
      randomId: '999999'
    })
    const router = useRouter()
    const onSubmit = (values) => {
      register({
        passwordConfirm: state.form.password,
        ...state.form
      }).then(res => {
        if(res.status == 200){
          if(res.data.code == 0){
            showNotify({ type: 'success', message: res.data.message })
            router.push('/home')
          }else{
            showNotify({ type: 'warning', message: res.data.message })
            router.push('/')
          }
        }else{
          showNotify({ type: 'warning', message: "响应非200" })
          router.push('/')
        }
      }).catch(err => {
        showNotify({ type: 'warning', message: err })
        console.log(err)
        router.push('/')
      })
    }
    state.validatorPassword = (val) => state.passwordGui.test(val)
    state.validatorTel = (val) => state.phoneGui.test(val)
    const methods = reactive({
      changePasswordIcon () {
        state.isShow = !state.isShow
      },
      // 发送验证码前的操作 先显示图片二维码
      loadingGainPhoneCodeFun () {
        state.codeImgNumber = ''
        methods.getCode()
      },
      //获取图片验证码
      getCode() {
        store.dispatch('getPhoneImg', state.randomId).then(res => {
          state.codeImg = true
          state.codeUrl = "data:image/gif;base64," + res.data.data
        })
      },
      // 获取验证码
      gainPhoneCodeFun () {
        if (state.isWaiting || state.isSend || state.codeImgNumber == '') return
        store.dispatch('GainRegisterPhoneCode', {
          phone: state.form.phone,
          randomId: state.randomId,
          captchaCode: state.codeImgNumber
        }).then(resp => {
          state.isSend = true
          state.codeImg = false
          showNotify({ type: 'success', message: '发送成功' })
          state.isWaiting = false
          methods.handleTime()
        })
      },
      cleanTimer () {
        clearInterval(state.timer)
        state.isSend = false
        state.waitingTime = 60
      },
      handleTime () {
        state.isSend = true
        state.timer = setInterval(() => {
          state.waitingTime--
          if (state.waitingTime <= 0) {
            methods.cleanTimer()
          }
        }, 1000)
      }
    })
    watch(state.form, (value) => {
      if (state.phoneGui.test(value.phone) && state.passwordGui.test(value.password)) {
        state.isClick = false
      } else {
        state.isClick = true
      }
    })
    return {
      ...toRefs(state),
      ...toRefs(methods),
      onSubmit
    }
  },
  components: {
    explanatory
  }
})
</script>
